/**
 * Created by Sana on 3/11/2019.
 */
import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import './page-not-found.scss';

class PageNotFound extends Component {
    render() {
        return (
            <div className="not-found-container">
                <div className="inner-container">
                    <img className="nf-img"
                         src={require('../../assets/images/404-page-icon.png')}
                         alt=""/>
                    <h2 className="nf-head">404 Oops! Page not found</h2>
                    <p className="nf-parah">We are very sorry for the
                        inconvenience, It looks like the
                        page you are trying to access, is either deleted or
                        never even existed</p>
                    <Link to="/">
                        <button className="nf-btn">Back to Home</button>
                    </Link>
                </div>
            </div>
        );
    }
}

export default PageNotFound;