import React, {Component} from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import TextField from "@material-ui/core/TextField";
import PageNotFound from '../components/page-not-found/page-not-found';
import {getFAQList} from "../common/api/FAQ.api";
import FaqComponent from "../components/faq/faq-component";
import BreadCrumbs from "../components/bread-crumbs";
import "./faqs.scss";

const breadCrumbsList  = [

    {
        title: "Home",
        link: "/",
    }

]

class Faqs extends Component {

    constructor(props) {
        super(props);
        this.state = {
            error: false,
            contentLoaded: false,
            faqItems: null
        }
    }

    componentDidMount() {
        window.analytics.page("Faqs");
        getFAQList()
            .then(({items}) => {

                this.setState({
                    ...this.state,
                    contentLoaded: true,
                    error: false,
                    faqItems: items,

                })
            })
            .catch(error => {
                console.log('failed to load FAQ List with error' + error);
                this.setState({
                    ...this.state,
                    error: true
                })
            });

    }

    renderFAQContent() {
        return (
            <>
                <SEO title="FAQs"/>
                <BreadCrumbs currentPage={"FAQS"} breadCrumbsList={breadCrumbsList}/>
                <div className="faq-title">
                    <h2 className="heading1">FAQs Screen</h2>
                    <p className="parah">Questions we get a lot, with our
                        answers.</p>
                </div>
                <div className="learning-lib-search">
                    <div className="input-wrap">
                        <img className="search-icon"
                             src={require('../assets/images/search_input.svg')}
                             alt="Search Icon"/>
                        <TextField
                            id="search-input"
                            className="search-input"
                            placeholder="Search frequently asked questions"
                            margin="normal"
                        />
                    </div>
                </div>
                <div className="faq-body">
                    {
                        this.state.faqItems.map((faqItem, index) => {
                            return (
                                <FaqComponent
                                    faqItem={faqItem}
                                    atIndex={index}
                                    key={index}
                                />
                            )
                        })
                    }
                </div>
            </>
        );
    }

    render() {
        if (!this.state.contentLoaded) {

            return null

        } else if (this.state.error) {

            return <PageNotFound/>

        } else {
            return (
                <Layout>
                    {this.state.faqItems ? this.renderFAQContent() : null}
                </Layout>
            )
        }
    }
}

export default Faqs
