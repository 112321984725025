const contentful = require('contentful');

//PROD
// const SPACE_ID = "3hywct1mp2jz"; 
//PROD
// const ACCESS_TOKEN = "oxFyKS8f_Arp_I4T72FhTOri38MJUPU8KktmUbyqXpU";  
//Dev
const SPACE_ID = "rcgb7l3o6mui"; 
//Dev
const ACCESS_TOKEN = "lynLdZZuempyxtYq83sVC7ojuls-KIpa1hT_cghYvrA";  

const HOST = 'cdn.contentful.com';
const CdnClient = contentful.createClient({

    //This is the space ID. A space is like a project folder in Contentful
    space: SPACE_ID,
    //This is the access token for this space
    accessToken: ACCESS_TOKEN,

    host: HOST
});

export default CdnClient;
